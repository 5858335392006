export const Element = {
  CardNumber: 'cardNumber',
  FirstName: 'firstName',
  LastName: 'lastName',
  ExpiryMonth: 'expiryMonth',
  ExpiryYear: 'expiryYear',
  Cvv: 'cvv',
};

export const InputState = {
  Standard: 'standard',
  Error: 'error',
  Success: 'success',
  Disabled: 'disabled',
};

export const MaxNameLength = 50;

export const MinNameLength = 1;

export const PaymentMethodTypes = {
  Card: 'card',
  ApplePay: 'applePay',
};
