import { useSelector } from 'react-redux';
import { PaymentMethodTypes } from '../constants';

const useExistingPaymentMethod = () => {
  const { creditCardType, creditCardNumber, expirationMonth, expirationYear } = useSelector(
    state => state.billing?.card ?? {}
  );

  const isCardAvailable = useSelector(state => {
    const card = state.billing?.card;
    return card && Object.keys(card).length > 0;
  });

  const cardNumber = creditCardNumber?.slice(-4);
  const cardExpiryDate = `${String(expirationMonth).padStart(2, '0')}/${String(expirationYear).slice(-2)}`;

  return {
    existingPaymentType: isCardAvailable ? PaymentMethodTypes.Card : null,
    existingPaymentDetails: { creditCardType, cardNumber, cardExpiryDate },
  };
};

export default useExistingPaymentMethod;
