import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BodyLarge400, BodyLarge500, TitleSmall500 } from '@nebula/global-styles';
import { isMobileApp } from '@experian-uk/web-common-mobile-view-helpers';

import GUIDANCE_MESSAGES from './constants/messaging';
import { Main, Container, Guidance, ThreeDsContainer } from './index.styles';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import paymentAuthorizedTransition from '../PaymentOrchestrator/transitions/paymentAuthorizedTransition';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';

const PaymentMethodAuthorization = () => {
  const paymentContext = useContext(PaymentContext);
  const { tokens, raisePaymentError, restartPaymentFlow } = paymentContext;

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSuccess = outputToken => {
    dispatch(paymentAuthorizedTransition(paymentContext, outputToken.id));
  };

  const onFailure = error => {
    raisePaymentError({
      message: error.message,
      group: PAYMENT_ERROR_GROUPS.AUTHORIZATION,
      type: PAYMENT_ERROR_TYPES.AUTHORIZATION.UNSUCCESSFUL_3DS,
    });
    restartPaymentFlow();
  };

  return (
    <Main>
      <Container>
        <Guidance>
          <TitleSmall500>Confirm your payment</TitleSmall500>
          <BodyLarge400 as="span">{`${GUIDANCE_MESSAGES.HEADING} `}</BodyLarge400>
          <BodyLarge500 as="span">
            {isMobileApp() ? GUIDANCE_MESSAGES.WARNING_APP : GUIDANCE_MESSAGES.WARNING_WEB}
          </BodyLarge500>
        </Guidance>
        <ThreeDsContainer actionTokenId={tokens.authorizationInput} onToken={onSuccess} onError={onFailure} />
      </Container>
    </Main>
  );
};

export default PaymentMethodAuthorization;
