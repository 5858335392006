import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonState, RadioButtonType, RadioGroup } from '@nebula/radio-button';
import { ApplePay } from '@epl/icons';
import MastercardLogoCurrent from '../../../../components/Icons/currentCard/mastercard.svg';
import VisaLogoCurrent from '../../../../components/Icons/currentCard/visa.svg';
import { RadioGroupContainer, CardImg } from './changePaymentRadioButtons.styles';
import { applePay } from '../../../../constants';

const ChangePaymentRadioButtons = ({ setPaymentMethodOption, paymentMethodOption }) => (
  <RadioGroupContainer>
    <RadioGroup
      testId="apple-pay-options"
      onChange={value => {
        setPaymentMethodOption(value);
      }}
      groupType={RadioButtonType.Standard}
      groupState={RadioButtonState.Standard}
      radioButtons={[
        {
          label: <ApplePay />,
          value: applePay.cardOption,
          isDefaultOption: paymentMethodOption === applePay.cardOption,
        },
        {
          label: (
            <div>
              <CardImg src={MastercardLogoCurrent} />
              <CardImg src={VisaLogoCurrent} />
            </div>
          ),
          value: applePay.applePayOption,
          isDefaultOption: paymentMethodOption === applePay.applePayOption,
        },
      ]}
    />
  </RadioGroupContainer>
);

ChangePaymentRadioButtons.propTypes = {
  setPaymentMethodOption: PropTypes.func,
  paymentMethodOption: PropTypes.string,
};

ChangePaymentRadioButtons.defaultProps = {
  setPaymentMethodOption: {},
  paymentMethodOption: applePay.applePayOption,
};

export default ChangePaymentRadioButtons;
