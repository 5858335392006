import { getEnv } from '../components/Context/env';

export const FETCH = 'FETCH';

export default function fetch(url, body = {}, meta = {}) {
  const env = getEnv();
  const fetchUrl = `${env.REACT_APP_GATEWAY_URL}${url}`;

  return {
    type: FETCH,
    payload: {
      url: fetchUrl,
      body,
    },
    meta,
  };
}
