import { useEffect, useState } from 'react';

import { InputState } from '../constants';

/**
 * Custom hook to validate form states and notify about form validity changes.
 *
 * @param {Array} statesToValidate - An array of states to validate. Each state can be an object or a single value.
 * @param {Function} onFormValidityChange - Callback function to notify about form validity changes.
 * @returns {boolean} - Returns the current form validity status.
 *
 * @example
 * const states = [
 *   { field1: InputState.Success, field2: InputState.Success },
 *   InputState.Success
 * ];
 * const handleFormValidityChange = (isValid) => {
 *   console.log('Form is valid:', isValid);
 * };
 * const formIsValid = useFormValidation(states, handleFormValidityChange);
 */

const useFormValidation = (statesToValidate, onFormValidityChange) => {
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (statesToValidate.length === 0) {
      setFormIsValid(false);
      onFormValidityChange(false);
      return;
    }

    const isValid = statesToValidate.every(state => {
      if (typeof state === 'object') {
        return Object.values(state).every(value => value === InputState.Success);
      }
      return state === InputState.Success;
    });

    setFormIsValid(isValid);
    onFormValidityChange(isValid);
  }, [statesToValidate, onFormValidityChange]);

  return formIsValid;
};

export default useFormValidation;
