import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { TitleLarge700, TitleMedium500, BodyLarge400, BodyMedium400 } from '@nebula/global-styles';
import { RadioButton, RadioButtonType, RadioButtonState } from '@nebula/radio-button';

import { getEnv } from '../Context/env';
import { PaymentMethodTypes } from './constants';
import useExistingPaymentMethod from './hooks/useExistingPaymentMethod';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import paymentMethodStoredTransition from '../PaymentOrchestrator/transitions/paymentMethodStoredTransition';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';
import SecurityCodeComponent from './PaymentForm/SecurityCode';
import PaymentForm from './PaymentForm';
import { SmallCardIcon } from './PaymentForm/Icons';
import {
  Main,
  Header,
  Review,
  Divider,
  Summary,
  CardCapture,
  PaymentDivider,
  PaymentCard,
  PaymentCardTitle,
  SubTitle,
  ConfirmButton,
  Spinner,
} from './index.styles';

const PaymentCaptureMethod = ({ heading }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState();
  const paymentContext = useContext(PaymentContext);
  const { paymentErrors, raisePaymentError } = paymentContext;

  const dispatch = useDispatch();

  const customer = useSelector(state => state.auth?.currentUser);
  const address = customer?.address?.[0];

  const { existingPaymentType, existingPaymentDetails } = useExistingPaymentMethod();

  const isCardAvailable = existingPaymentType === PaymentMethodTypes.Card;
  const { creditCardType, cardNumber, cardExpiryDate } = existingPaymentDetails;

  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();
  const showApplePay = !!window?.ApplePaySession?.canMakePayments?.();

  const handleFormValidityChange = isValid => setIsFormValid(isValid);

  const handleSubmitAttempt = () => {
    setButtonDisabled(true);
  };

  const handleSuccess = async cardToken => {
    await paymentMethodStoredTransition(paymentContext, cardToken.id)(dispatch);
    setButtonDisabled(false);
  };

  const handleFailure = error => {
    setButtonDisabled(false);
    raisePaymentError({
      message: error.message,
      group: PAYMENT_ERROR_GROUPS.TOKENIZATION,
      type: PAYMENT_ERROR_TYPES.TOKENIZATION.GENERATING_PAYMENT_TOKEN,
    });
  };

  const handlePaymentMethodSelection = value => setSelectedPaymentMethod(value);

  return (
    <Main>
      <Header>
        <TitleLarge700 as="h1">{heading}</TitleLarge700>
      </Header>
      <Review>
        <Summary>
          <BodyMedium400>Review order</BodyMedium400>
          {paymentErrors.map(error => (
            <p key={error.type}>Error: {error.message}</p>
          ))}
        </Summary>
      </Review>
      <CardCapture>
        <RecurlyProvider publicKey={key}>
          <Elements>
            <TitleMedium500 as="h2">Payment details</TitleMedium500>
            <BodyLarge400>No payment is taken during your trial</BodyLarge400>
            {showApplePay && (
              <React.Fragment>
                <apple-pay-button buttonstyle="black" type="pay" locale="en-GB" />
                <PaymentDivider>or</PaymentDivider>
              </React.Fragment>
            )}
            <SubTitle as="h3">Pay by card</SubTitle>
            {isCardAvailable ? (
              <React.Fragment>
                <PaymentCard>
                  <RadioButton
                    label={
                      <PaymentCardTitle>
                        <SmallCardIcon creditCardType={creditCardType} /> •••• {cardNumber} | Expires {cardExpiryDate}
                      </PaymentCardTitle>
                    }
                    value="1"
                    index={1}
                    radioType={RadioButtonType.Standard}
                    radioState={RadioButtonState.Standard}
                    width="100%"
                    isChecked={selectedPaymentMethod === '1'}
                    isDisabled={false}
                    onRadioButtonSelected={() => handlePaymentMethodSelection('1')}
                  />
                  {selectedPaymentMethod === '1' && (
                    <React.Fragment>
                      <Divider />
                      <SecurityCodeComponent onFormValidityChange={handleFormValidityChange} />
                    </React.Fragment>
                  )}
                </PaymentCard>
                <PaymentCard>
                  <RadioButton
                    label={<PaymentCardTitle>Use a different card</PaymentCardTitle>}
                    value="2"
                    index={2}
                    radioType={RadioButtonType.Standard}
                    radioState={RadioButtonState.Standard}
                    width="100%"
                    isChecked={selectedPaymentMethod === '2'}
                    isDisabled={false}
                    onRadioButtonSelected={() => handlePaymentMethodSelection('2')}
                  />
                  {selectedPaymentMethod === '2' && (
                    <PaymentForm
                      customer={customer}
                      address={address}
                      onSubmitAttempt={handleSubmitAttempt}
                      onSuccess={handleSuccess}
                      onFailure={handleFailure}
                      onFormValidityChange={handleFormValidityChange}
                    />
                  )}
                </PaymentCard>
              </React.Fragment>
            ) : (
              <PaymentCard>
                <PaymentForm
                  customer={customer}
                  address={address}
                  onSubmitAttempt={handleSubmitAttempt}
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  onFormValidityChange={handleFormValidityChange}
                />
              </PaymentCard>
            )}
            <ConfirmButton
              data-automation-test-element="confirmation-button"
              disabled={!isFormValid || buttonDisabled}
              label={buttonDisabled ? <Spinner /> : 'Start my free trial'}
              buttonKind="primaryMagenta"
              type="submit"
              form="recurly-payment-form"
            />
          </Elements>
        </RecurlyProvider>
      </CardCapture>
    </Main>
  );
};

PaymentCaptureMethod.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default PaymentCaptureMethod;
