import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import fetch from '../fetch';
import { getEnv } from '../../components/Context/env';
import errorMessages from './errorMessages';
import { defined } from '../../helpers/defined';
import { resolveChallengeStatus } from './resolveChallengeStatus';
import CardSaveError from '../../exceptions/cardSaveError';
import { maxTimeout, pollingInterval } from '../../constants/polling3ds';

export const AUTHORIZE_CARD = 'AUTHORIZE_CARD';
export const CARD_AUTHORIZED = 'CARD_AUTHORIZED';
export const CHALLENGE_PRESENTED = 'CHALLENGE_PRESENTED';
export const billingAuthorizationEndpoint = 'billingpaymentauthorizations';

const submitMilestoneECD = milestone => {
  Analytics.publishOnce(MilestoneInputEvent.fromObject(milestone));
};

const raiseChallengeECD = success =>
  submitMilestoneECD({
    product_movement_3ds_check: {
      interaction_status: success,
    },
  });

const raise200AuthoriseECD = success =>
  submitMilestoneECD({
    product_movement_put_billingpaymentauthorizations_200: {
      interaction_status: success,
    },
  });

const raiseFailAuthoriseECD = () =>
  submitMilestoneECD({
    product_movement_put_billingpaymentauthorizations_fail: {
      interaction_status: false,
    },
  });

export default (card, offerId, isPaymentMethodChange) => async dispatch => {
  dispatch({ type: AUTHORIZE_CARD, meta: { clearErrors: true }, payload: {} });

  const env = getEnv();

  if (!defined('object', card) || !defined('string', offerId)) {
    return dispatch({
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.validation)),
      type: CARD_AUTHORIZED,
    });
  }

  // acceptHeader and remoteAddress added in kappa proxy
  const billing3dsPayload = {
    offerId,
    paymentType: 'creditcard',
    paymentMethodChange: isPaymentMethodChange,
    returnurl: env.REACT_APP_RETURN_URL_3DS,
    creditCardInfo: {
      ...card,
      cvv: card.CVV,
    },
    browser: {
      acceptHeader: '',
      screenColourDepth: window.screen.colorDepth,
      javaScriptEnabled: true,
      language: 'en',
      remoteAddress: '',
      screenResolution: `${window.screen.availWidth}X${window.screen.availHeight}`,
      timezone: new Date().getTimezoneOffset().toString(),
      userAgentHeader: navigator.userAgent,
    },
  };

  try {
    const authRes = await dispatch(
      fetch(
        `/${billingAuthorizationEndpoint}`,
        {
          method: 'PUT',
          body: billing3dsPayload,
        },
        { require3dsHeaders: true }
      )
    );

    if (authRes.data[0].success) {
      raise200AuthoriseECD(authRes.data[0].success);
    } else {
      raiseFailAuthoriseECD();
    }

    const challengeUrl = authRes.data[0].challengeRedirectUrl;

    // 3DS Challenge not required where challengeUrl is empty
    if (!authRes.data[0].success || !challengeUrl) {
      return dispatch({
        payload: { authorized: true },
        type: CARD_AUTHORIZED,
      });
    }

    // Trigger UI update
    dispatch({
      type: CHALLENGE_PRESENTED,
      payload: { challengeUrl },
    });

    // Begin polling challenge status
    const challengeResponse = await resolveChallengeStatus(
      billingAuthorizationEndpoint,
      dispatch,
      maxTimeout,
      pollingInterval
    );

    switch (challengeResponse) {
      case 'ServiceError':
        raiseChallengeECD(false);
        return dispatch({
          error: true,
          payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(null, 'threeDSValidationError'))),
          type: CARD_AUTHORIZED,
        });
      case 'Success':
        raiseChallengeECD(true);
        return dispatch({
          type: CARD_AUTHORIZED,
          payload: { authorized: true },
        });
      case 'Stopped':
        raiseChallengeECD(false);
        return false;
      default:
        // For "Fail" response from timeout or unauthorised
        raiseChallengeECD(false);
        return dispatch({
          error: true,
          payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(null, 'threeDSValidationError'))),
          type: CARD_AUTHORIZED,
        });
    }
  } catch (error) {
    raiseFailAuthoriseECD();
    return dispatch({
      error: true,
      payload: new CardSaveError(
        JSON.stringify(errorMessages.cardAuthorization(error.status, 'authorizePaymentMethod'))
      ),
      type: CARD_AUTHORIZED,
    });
  }
};

export const skipAuthorization =
  (shouldSkipAuth = true) =>
  async dispatch =>
    dispatch({
      type: CARD_AUTHORIZED,
      payload: {
        authorized: shouldSkipAuth,
      },
    });
