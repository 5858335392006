import { useState } from 'react';

/**
 * Custom hook to handle focus and blur events for elements and nested elements.
 *
 * @param {Object} ref - A React ref object that points to the container element.
 * @returns {Object} An object containing:
 * - {HTMLElement|null} focusedElement - The currently focused element.
 * - {Function} handleFocus - Function to set the focused element.
 * - {Function} handleBlur - Function to clear the focused element.
 * - {HTMLElement|null} focusedNestedElement - The currently focused nested element.
 * - {Function} handleNestedFocus - Function to set the focused nested element.
 * - {Function} handleNestedBlur - Function to clear the focused nested element if the active element is not within the ref container.
 *
 * @example
 * const ref = useRef(null);
 * const {
 *   focusedElement,
 *   handleFocus,
 *   handleBlur,
 *   focusedNestedElement,
 *   handleNestedFocus,
 *   handleNestedBlur,
 * } = useFocusHandler(ref);
 *
 * <div ref={ref}>
 *   <input
 *     onFocus={handleFocus('input1')}
 *     onBlur={handleBlur}
 *   />
 *   <div>
 *     <input
 *       onFocus={handleNestedFocus('nestedInput1')}
 *       onBlur={handleNestedBlur}
 *     />
 *   </div>
 * </div>
 */

const useFocusHandler = ref => {
  const [focusedElement, setFocusedElement] = useState(null);
  const handleFocus = element => () => setFocusedElement(element);
  const handleBlur = () => setFocusedElement(null);

  const [focusedNestedElement, setfocusedNestedElement] = useState(null);
  const handleNestedFocus = element => () => setfocusedNestedElement(element);
  const handleNestedBlur = () => !ref.current.contains(document.activeElement) && setfocusedNestedElement(null);

  return {
    focusedElement,
    handleFocus,
    handleBlur,
    focusedNestedElement,
    handleNestedFocus,
    handleNestedBlur,
  };
};

export default useFocusHandler;
