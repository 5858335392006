import styled, { css, keyframes } from 'styled-components';
import { TitleLarge700, TitleMedium500, TitleSmall500, BodyLarge400, BodyMedium400 } from '@nebula/global-styles';
import Button from '@nebula/button';

const Padding = css`
  padding: 0 1rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    padding: 0;
  }
`;

export const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 596px) 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    '. header .'
    '. review .'
    '. card-capture .'
    '. confirmation .';
  grid-gap: 1.5rem 0;
  align-items: center;
  margin: 1.5rem 0;
  @media ${({ theme }) => theme.media.smallDesktopMin} {
    grid-gap: 2rem 0;
    margin: 3rem 0 8.125rem;
  }
`;

export const Header = styled.header`
  grid-area: header;
  display: grid;
  grid-gap: 1rem;
  ${Padding}
  > ${TitleLarge700} {
    margin: 0;
  }
`;

export const Review = styled.section`
  grid-area: review;
  ${Padding}
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.color.ui.grey[20]};
  border: 0;
`;

export const Summary = styled.div`
  grid-area: review;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.decorative.sapphire[10]};
  border-radius: 0.75rem;
  > ${BodyMedium400} {
    margin: 0;
    color: ${({ theme }) => theme.color.ui.grey[100]};
  }
`;

export const CardCapture = styled.section`
  grid-area: card-capture;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.ui.grey[10]};
  > ${TitleMedium500} {
    margin: 0.5rem 0 0.25rem;
  }
  > ${BodyLarge400} {
    margin: 0 0 1rem;
  }
  input[type='radio'] {
    margin: 0;
  }
  apple-pay-button {
    --apple-pay-button-width: 100%;
    --apple-pay-button-height: 3rem;
    --apple-pay-button-padding: 0.5rem 1rem;
    --apple-pay-button-border-radius: 0.375rem;
    --apple-pay-button-box-sizing: border-box;
    @media ${({ theme }) => theme.media.smallDesktopMin} {
      --apple-pay-button-width: 23rem;
    }
  }
`;

export const SubTitle = styled(TitleSmall500)`
  margin: 0 0 0.625rem;
`;

export const PaymentDivider = styled(TitleSmall500)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 0.625rem;
  margin: 0.75rem 0;

  ::before,
  ::after {
    content: '';
    border-top: 0.0625rem double ${({ theme }) => theme.color.ui.grey[50]};
    align-self: center;
  }
`;

export const PaymentCard = styled.div`
  margin: 0 0 1rem;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.color.brand.white};
  border: 0.0625rem solid ${({ theme }) => theme.color.ui.grey[20]};
  border-radius: ${({ theme }) => theme.borderRadius.small.rem};
  box-shadow: ${({ theme }) => theme.elevation.raised10};
`;

export const PaymentCardTitle = styled(TitleSmall500)`
  display: flex;
  align-items: center;
  margin: 0;
  > svg {
    margin-right: 1rem;
  }
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  font-size: 0.875rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    width: 11.375rem;
    font-size: 1rem;
  }
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
  justify-content: center;
  display: none;
  height: 1.25rem;
  width: 1.25rem;
  animation: ${spin} 0.75s infinite linear;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.borderRadius.circle.rem};
  border: 0.125rem solid rgba(255, 255, 255, 0.25);
  border-top-color: ${({ theme }) => theme.color.ui.white};

  @media ${({ theme }) => theme.media.shouldAnimate} {
    display: flex;
  }
`;
