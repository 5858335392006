import React from 'react';
import PropTypes from 'prop-types';

const tableDataStyle = { border: '1px solid black', padding: '8px', textAlign: 'left' };

const TemporaryOfferDetails = ({ offer }) => (
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '4rem' }}>
    <table style={{ borderCollapse: 'collapse', width: '50%' }}>
      <tbody>
        <tr>
          <td style={tableDataStyle}>Selected product family:</td>
          <td style={tableDataStyle}>{offer.family}</td>
        </tr>
        <tr>
          <td style={tableDataStyle}>Selected offer:</td>
          <td style={tableDataStyle}>
            {offer.upc} - {offer.name}
          </td>
        </tr>
        <tr>
          <td style={tableDataStyle}>Selected offer ID:</td>
          <td style={tableDataStyle}>{offer.id}</td>
        </tr>
        <tr>
          <td style={tableDataStyle}>Eligible for Trial:</td>
          <td style={tableDataStyle}>{String(offer.descriptors.includes('free_trial'))}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

TemporaryOfferDetails.propTypes = {
  offer: PropTypes.shape({
    family: PropTypes.string,
    name: PropTypes.string,
    upc: PropTypes.string,
    id: PropTypes.string,
    descriptors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default TemporaryOfferDetails;
