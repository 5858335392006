import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { ErrorIcon, iconSizes, TickCircleIcon } from '@nebula/icons/ui';

import { InputState } from '../constants';
import { IconsContainer } from './index.styles';

export const DefaultCardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none">
    <title>card icon</title>
    <path
      clipRule="evenodd"
      d="M18 2H2a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5v-11A.5.5 0 0 0 18 2ZM2 .5h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2Z"
      fill="#252C34"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.031 10.004a1 1 0 0 1 1-1h.75a1 1 0 1 1 0 2h-.75a1 1 0 0 1-1-1ZM6.762 10.004a1 1 0 0 1 1-1h.75a1 1 0 0 1 0 2h-.75a1 1 0 0 1-1-1ZM10.508 10.004a1 1 0 0 1 1-1h.75a1 1 0 1 1 0 2h-.75a1 1 0 0 1-1-1ZM14.246 10.004a1 1 0 0 1 1-1h.75a1 1 0 1 1 0 2h-.75a1 1 0 0 1-1-1Z"
      fill="#252C34"
      fillRule="evenodd"
    />
    <path
      d="M3 4.906c0-.69.56-1.25 1.25-1.25h1.504a1.25 1.25 0 0 1 0 2.5H4.25c-.69 0-1.25-.56-1.25-1.25Z"
      fill="#252C34"
    />
  </svg>
);

export const MasterCard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" fill="none">
    <title>mastercard</title>
    <path fill="#fff" d="M-.001.086h24v13.827h-24z" opacity=".01" />
    <path fill="#FF5F00" d="M8.716 1.858h6.564v10.283H8.716z" />
    <path
      d="M9.394 7c-.002-2.007.958-3.902 2.604-5.14-2.795-2.108-6.81-1.8-9.222.704-2.412 2.505-2.412 6.368 0 8.873 2.412 2.505 6.427 2.812 9.222.705C10.352 10.902 9.392 9.006 9.394 7Z"
      fill="#EB001B"
    />
    <path
      d="M22.487 10.968v.819h-.076v-.819h-.161v-.17h.409v.17h-.172Zm.794-.17v.989h-.086v-.748l-.134.642h-.092l-.134-.642v.748h-.09v-.988h.124l.144.705.144-.705h.124ZM23.03 7c0 2.504-1.491 4.788-3.84 5.882-2.348 1.094-5.14.806-7.193-.741 1.645-1.24 2.605-3.135 2.605-5.142 0-2.006-.96-3.901-2.605-5.14C14.05.31 16.843.022 19.191 1.116S23.029 4.495 23.029 7V7Z"
      fill="#F79E1B"
    />
  </svg>
);

export const Visa = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>visa</title>
    <path
      d="M13.0268 7C11.6068 7 10.3377 7.73614 10.3377 9.0959C10.3377 10.6555 12.5884 10.7632 12.5884 11.5467C12.5884 11.8767 12.2104 12.172 11.5646 12.172C10.6482 12.172 9.96336 11.7594 9.96336 11.7594L9.67027 13.1317C9.67027 13.1317 10.4593 13.4803 11.5067 13.4803C13.0593 13.4803 14.2811 12.7081 14.2811 11.3249C14.2811 9.67688 12.021 9.57237 12.021 8.84525C12.021 8.58679 12.3313 8.3037 12.9752 8.3037C13.7017 8.3037 14.2943 8.6038 14.2943 8.6038L14.5812 7.27848C14.5812 7.27848 13.9361 7 13.0266 7H13.0268ZM0.0344344 7.1001L0 7.3001C0 7.3001 0.597397 7.40941 1.13554 7.62763C1.82843 7.87768 1.87768 8.02342 1.99439 8.47548L3.26587 13.377H4.97037L7.5962 7.1001H5.8957L4.20841 11.3678L3.51992 7.75015C3.45686 7.33614 3.13694 7.0999 2.74555 7.0999H0.0346346L0.0344344 7.1001ZM8.28008 7.1001L6.94615 13.377H8.56777L9.8969 7.1001H8.28008ZM17.3243 7.1001C16.9333 7.1001 16.7261 7.30951 16.5742 7.67528L14.1984 13.377H15.8989L16.2278 12.4266H18.2995L18.4995 13.377H20L18.6911 7.1001H17.3243ZM17.5455 8.796L18.0497 11.1514H16.6993L17.5457 8.796H17.5455Z"
      fill="#1434CB"
    />
  </svg>
);

export const CardIcons = () => (
  <IconsContainer>
    <Visa />
    <MasterCard />
  </IconsContainer>
);

export const SmallCardIcon = ({ creditCardType }) => {
  switch (creditCardType) {
    case 'Visa':
      return (
        <svg width="40" height="14" viewBox="0 0 40 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>visa</title>
          <path
            d="M26.0537 0.519775C23.2136 0.519775 20.6755 1.99205 20.6755 4.71157C20.6755 7.83069 25.1768 8.0461 25.1768 9.61327C25.1768 10.2731 24.4208 10.8637 23.1291 10.8637C21.2965 10.8637 19.9267 10.0385 19.9267 10.0385L19.3405 12.7832C19.3405 12.7832 20.9185 13.4803 23.0134 13.4803C26.1185 13.4803 28.5622 11.936 28.5622 9.16963C28.5622 5.87353 24.042 5.66452 24.042 4.21027C24.042 3.69335 24.6627 3.12718 25.9503 3.12718C27.4034 3.12718 28.5886 3.72738 28.5886 3.72738L29.1624 1.07673C29.1624 1.07673 27.8723 0.519775 26.0533 0.519775H26.0537ZM0.0688689 0.719976L0 1.11998C0 1.11998 1.19479 1.33859 2.27107 1.77503C3.65686 2.27513 3.75536 2.56662 3.98879 3.47073L6.53173 13.2737H9.94074L15.1924 0.719976H11.7914L8.41682 9.25531L7.03984 2.02008C6.91371 1.19205 6.27387 0.719575 5.49109 0.719575H0.0692693L0.0688689 0.719976ZM16.5602 0.719976L13.8923 13.2737H17.1355L19.7938 0.719976H16.5602ZM34.6486 0.719976C33.8667 0.719976 33.4523 1.13879 33.1483 1.87033L28.3968 13.2737H31.7978L32.4557 11.373H36.599L36.999 13.2737H40L37.3822 0.719976H34.6486ZM35.0911 4.11177L36.0993 8.82248H33.3986L35.0915 4.11177H35.0911Z"
            fill="#1434CB"
          />
        </svg>
      );
    case 'MasterCard':
      return (
        <svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>mastercard</title>
          <path d="M23.9578 3.08936H14.043V20.9066H23.9578V3.08936Z" fill="#FF5F00" />
          <path
            d="M14.6727 11.998C14.6712 10.2821 15.06 8.58826 15.8098 7.04482C16.5596 5.50139 17.6508 4.14877 19.0006 3.08937C17.329 1.77546 15.3215 0.958363 13.2075 0.731464C11.0934 0.504564 8.95824 0.877012 7.04589 1.80625C5.13354 2.7355 3.52121 4.18404 2.3932 5.9863C1.26518 7.78856 0.666992 9.87182 0.666992 11.998C0.666992 14.1241 1.26518 16.2074 2.3932 18.0097C3.52121 19.8119 5.13354 21.2605 7.04589 22.1897C8.95824 23.119 11.0934 23.4914 13.2075 23.2645C15.3215 23.0376 17.329 22.2205 19.0006 20.9066C17.6508 19.8472 16.5597 18.4946 15.8098 16.9511C15.06 15.4077 14.6712 13.7139 14.6727 11.998Z"
            fill="#EB001B"
          />
          <path
            d="M37.334 11.998C37.3341 14.1241 36.736 16.2074 35.608 18.0096C34.4801 19.8119 32.8678 21.2604 30.9555 22.1897C29.0432 23.1189 26.908 23.4914 24.794 23.2645C22.68 23.0376 20.6725 22.2205 19.001 20.9066C20.3496 19.8461 21.4399 18.4933 22.1896 16.9501C22.9393 15.4069 23.3288 13.7136 23.3288 11.998C23.3288 10.2823 22.9393 8.58906 22.1896 7.04587C21.4399 5.50268 20.3496 4.14984 19.001 3.08937C20.6725 1.77546 22.68 0.958357 24.794 0.731461C26.908 0.504564 29.0432 0.877029 30.9555 1.80628C32.8678 2.73553 34.4801 4.18407 35.608 5.98633C36.736 7.78859 37.3341 9.87185 37.334 11.998Z"
            fill="#F79E1B"
          />
        </svg>
      );
    default:
      return null;
  }
};

SmallCardIcon.propTypes = {
  creditCardType: PropTypes.string.isRequired,
};

const Container = styled.div`
  cursor: ${({ onClick, $state }) => (onClick && $state !== InputState.Disabled ? 'pointer' : 'unset')};
  display: flex;
  align-items: center;
  justify-self: flex-end;
`;

export const ValidationStatusIcon = ({ state }) => {
  const theme = useContext(ThemeContext);

  if (state === InputState.Standard) {
    return null;
  }

  const iconProps = {
    iconSize: iconSizes.small,
    iconColor: state === InputState.Error ? theme.color.messaging.red[60] : theme.color.messaging.green[60],
  };

  return (
    <Container $state={state}>
      {state === InputState.Error && <ErrorIcon {...iconProps} />}
      {state === InputState.Success && <TickCircleIcon {...iconProps} />}
    </Container>
  );
};

ValidationStatusIcon.defaultProps = {
  state: InputState.Standard,
};

ValidationStatusIcon.propTypes = {
  state: PropTypes.string,
};

export const Cvv = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>cvv</title>
    <path
      d="M29.3333 5.33301H2.66667C1.19391 5.33301 0 6.52692 0 7.99967V23.9997C0 25.4724 1.19391 26.6663 2.66667 26.6663H29.3333C30.8061 26.6663 32 25.4724 32 23.9997V7.99967C32 6.52692 30.8061 5.33301 29.3333 5.33301Z"
      fill="#C8CCD1"
    />
    <path d="M32 9.33301H0V11.9997H32V9.33301Z" fill="#495765" />
    <path
      d="M26.7246 15.333H10.7246C9.62004 15.333 8.72461 16.2284 8.72461 17.333V19.9997C8.72461 21.1042 9.62004 21.9997 10.7246 21.9997H26.7246C27.8292 21.9997 28.7246 21.1042 28.7246 19.9997V17.333C28.7246 16.2284 27.8292 15.333 26.7246 15.333Z"
      fill="white"
      stroke="#E80070"
    />
    <path
      d="M23.9642 19.9997C24.7006 19.9997 25.2975 19.4027 25.2975 18.6663C25.2975 17.93 24.7006 17.333 23.9642 17.333C23.2278 17.333 22.6309 17.93 22.6309 18.6663C22.6309 19.4027 23.2278 19.9997 23.9642 19.9997Z"
      fill="#252C34"
    />
    <path
      d="M18.724 19.9997C19.4603 19.9997 20.0573 19.4027 20.0573 18.6663C20.0573 17.93 19.4603 17.333 18.724 17.333C17.9876 17.333 17.3906 17.93 17.3906 18.6663C17.3906 19.4027 17.9876 19.9997 18.724 19.9997Z"
      fill="#252C34"
    />
    <path
      d="M13.3333 19.9997C14.0697 19.9997 14.6667 19.4027 14.6667 18.6663C14.6667 17.93 14.0697 17.333 13.3333 17.333C12.597 17.333 12 17.93 12 18.6663C12 19.4027 12.597 19.9997 13.3333 19.9997Z"
      fill="#252C34"
    />
  </svg>
);
