import styled from 'styled-components';

export const FieldSet = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const DateSelectorContainer = styled.div`
  width: 100%;
  margin-top: ${props => (props.name === 'expirationYear' ? '1.75rem' : '')};
  span {
    font-size: 1rem;
  }
  div > select {
    background-color: white;
  }
`;
