import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { sendMobileErrorNotification } from '@experian-uk/web-common-mobile-view-helpers';

import MobileSwitch from './switch';
import getCustomer from '../../actions/auth/getCustomer';
import getAllOffers from '../../actions/offers/getAll';
import getAllSubscriptions from '../../actions/subscriptions/getAll';
import getScore from '../../actions/scores/getScore';
import getBillingProvider from '../../actions/billing/getBillingProvider';
import BILLING_PROVIDERS from '../../constants/billingProviders';

const CORE_DEPENDENCIES = [getCustomer, getAllOffers, getScore, getAllSubscriptions];

// A home for Webview routes - will be responsible for anything that needs to be available to all routes
const MobileRoutes = () => {
  const [initialised, setInitialised] = useState(false);

  const mobileSwitchEnabled = useSelector(state => state.billing.billingProvider === BILLING_PROVIDERS.NEW);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialise = async () => {
      /* Check we can establish a users billing provider before continuing, this will redirect to an error
    screen if the provider cannot be established */
      try {
        await dispatch(getBillingProvider());
        await Promise.allSettled(CORE_DEPENDENCIES.map(dependency => dispatch(dependency())));
        setInitialised(true);
      } catch (error) {
        sendMobileErrorNotification();
      }
    };

    initialise();
  }, [dispatch]);

  if (!initialised) {
    // a user is seeing a native spinner
    return null;
  }

  // core deps available, each route will be responsible for telling the app that it's loaded
  return <Route exact path="/mobile/switch" component={mobileSwitchEnabled ? MobileSwitch : null} />;
};

export default MobileRoutes;
