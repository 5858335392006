/* eslint-disable no-unused-vars */

import fetch from '../../../actions/fetch';

const PURCHASE_ENDPOINT = '/billingsubscriptions/account';
const THREE_DS_REQUIRED_STATUS_CODE = 422;

const getPaymentAuthorizationToken = errorResponseBody =>
  errorResponseBody?.status === THREE_DS_REQUIRED_STATUS_CODE
    ? errorResponseBody.errorResponse?.PaymentAuthorizationRequestToken
    : null;

const startPurchase =
  ({ targetOfferId, paymentMethodToken, authorizationToken }) =>
  async dispatch => {
    const requestBody = {
      method: 'PUT',
      body: {
        offerId: targetOfferId,
        paymentAuthorizationResultToken: authorizationToken,
        changeCurrentPaymentMethod: false,
        paymentMethodToken,
      },
    };
    const requestMeta = {};

    try {
      await dispatch(fetch(PURCHASE_ENDPOINT, requestBody, requestMeta));

      return {
        complete: true,
        error: null,
        threeDSecureActionTokenId: null,
      };
    } catch (paymentErrorResponse) {
      const errorResponseBody = await paymentErrorResponse?.json?.().catch(() => ({}));
      const paymentAuthorizationToken = getPaymentAuthorizationToken(errorResponseBody);

      if (paymentAuthorizationToken) {
        return {
          complete: false,
          error: null,
          threeDSecureActionTokenId: paymentAuthorizationToken,
        };
      }

      return {
        complete: false,
        error: 'ERROR_NOT_3DS_TRIGGER_RESET',
        threeDSecureActionTokenId: null,
      };
    }
  };

export default startPurchase;
