import React from 'react';
import PropTypes from 'prop-types';
import ChangePaymentRadioButtons from '../paymentOptions/changePaymentRadioButtons';
import { applePay } from '../../../../constants';

const ChangePaymentMethod = ({ setPaymentMethodOption, paymentMethodOption }) => (
  <ChangePaymentRadioButtons
    setPaymentMethodOption={setPaymentMethodOption}
    paymentMethodOption={paymentMethodOption}
  />
);

ChangePaymentMethod.propTypes = {
  setPaymentMethodOption: PropTypes.func.isRequired,
  paymentMethodOption: PropTypes.string,
};

ChangePaymentMethod.defaultProps = {
  paymentMethodOption: applePay.applePayOption,
};

export default ChangePaymentMethod;
