import styled from 'styled-components';
import Button from '@nebula/button';

export const StyledButton = styled(Button)`
  span {
    font-size: 16px;
    font-family: 'Roboto';
  }
`;

export const StyledChangePaymentMethodSection = styled.section`
  padding-left: 0%;
  padding-top: 8px;
  gap: 32px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    padding-left: 50%;
    padding-top: 24x;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  apple-pay-button {
    --apple-pay-button-height: 54px;
    --apple-pay-button-border-radius: 6px;
  }
`;
