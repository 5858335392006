import { BodyLarge400, BodyLarge500, TitleSmall500 } from '@nebula/global-styles';
import { ThreeDSecureAction } from '@recurly/react-recurly';
import { rem } from 'polished';
import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    gap: 2.5rem;
  }
`;

export const Guidance = styled.div`
  background-color: ${({ theme }) => theme.color.decorative.sapphire[10]};
  border-radius: 0.75rem;

  ${TitleSmall500} {
    margin: 0 0 4px 0;
  }

  ${BodyLarge400}, ${BodyLarge500} {
    margin: 0;
  }

  margin-bottom: 0.5rem;
  max-width: 596px;
  padding: 1rem;
`;

export const GuidanceMessageBreak = styled.br`
  display: none;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    display: block;
  }
`;

export const ThreeDsContainer = styled(ThreeDSecureAction)`
  border: 1px solid ${({ theme }) => theme.color.ui.grey[20]};
  border-radius: 0.75rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  height: ${rem(400)};
  padding: 16px;
`;
