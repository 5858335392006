import deepmerge from 'deepmerge';
import Cookies from 'js-cookie';
import { FETCH } from '../actions/fetch';
import { getEnv } from '../components/Context/env';
import { nonEssentialServices } from '../constants';
import isServer from '../helpers/isServer';
import setCurrentUser from '../actions/auth/setCurrentUser';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-brand': 'creditmatcher',
  'x-api-version': '3.0.0',
  'x-clientid': 'web_prodmove',
};

const additionalHeaders = {
  THREE_DS_CAPABLE: 'x-Is3DsCapable',
  CUSTOMER_ID: 'x-customerId',
  CSRF_TOKEN: 'CSRF-Token',
};

const constructRequestHeaders = (payload, meta, auth) => {
  const headers = {
    ...defaultHeaders,
    ...(payload.headers || {}),
    [additionalHeaders.CSRF_TOKEN]: auth?.csrfToken,
  };

  if (meta?.require3dsHeaders) {
    headers[additionalHeaders.THREE_DS_CAPABLE] = true;
  }

  if (auth?.currentUser?.customerId) {
    headers[additionalHeaders.CUSTOMER_ID] = auth.currentUser.customerId;
  }

  return headers;
};

const constructFetchUrl = (gatewayUrl, originalUrl, payload, auth) => {
  const proxyUrlDisplay = payload.service
    ? `${gatewayUrl}/${payload.service.service}/${payload.service.endpoint}`
    : originalUrl;

  let fetchUrl = `/internal/proxy?for=${proxyUrlDisplay}&method=${
    typeof payload.method === 'undefined' ? 'GET' : payload.method
  }`;

  if (auth?.mockData) {
    fetchUrl = payload.service ? `/internal/mock${proxyUrlDisplay}` : `/internal${proxyUrlDisplay}`;
  }

  return fetchUrl;
};

const isEssentialService = requestUrl => {
  const env = getEnv();
  const isMocks = env.REACT_APP_ENV === 'local';
  const { pathname } = new URL(requestUrl);
  const serviceEndpoint = isMocks ? pathname.replace('/mock/', '') : pathname.replace('/api/', '');

  const isAllowedTo403 = nonEssentialServices.some(allowedService => {
    // Get endpoint from the request URL and compare this with the allowed endpoints
    let serviceName = serviceEndpoint;

    if (serviceEndpoint.indexOf('/') > -1) {
      // Has extended endpoint e.g. billingsubscriptions/account
      [serviceName] = serviceEndpoint.split('/');
    }

    return String(serviceName) === allowedService;
  });

  return !isAllowedTo403;
};

const handleNonOkResponse = (response, requestUrl, logoutProcedure) => {
  // Exit early if not 403
  if (response.status !== 403) {
    /* Match pre-existing handling error handling - throw the response body. This could be 
      reworked to be more intuitive but it has a wide impact so leaving during the billing migration, for now
    */
    throw response;
  }

  // Check if 403 on this service is ok, force logout if this is a core service
  const shouldLogout = isEssentialService(requestUrl);

  if (shouldLogout) {
    logoutProcedure();
    return null;
  }

  // Throw the 403 but allow the customer to continue
  throw response;
};

const handleOkResponse = async response => {
  let responseContent = null;
  try {
    responseContent = await response.json();
  } catch (error) {
    if (response.status !== 204) {
      throw error;
    }
  }
  return responseContent;
};

export default store => next => action => {
  if (action.type !== FETCH) return next(action);

  const env = getEnv();
  const { url, body = {} } = action.payload;
  const { overrideToken } = action.meta;
  const { auth } = store.getState();
  const token = overrideToken || auth?.token;
  const payloadBody = deepmerge({}, body);

  const logoutProcedure = () => {
    Cookies.remove('authId');
    store.dispatch(setCurrentUser());
    if (!isServer() && window.location) {
      window.location = env.REACT_APP_AUTH_URL;
    }
  };

  const sharedHeaders = constructRequestHeaders(action.payload, action.meta, auth);

  const internalUrl = constructFetchUrl(env.REACT_APP_GATEWAY_URL, url, payloadBody, auth);

  const proxiedBody = {
    ...payloadBody,
    headers: { ...sharedHeaders },
    meta: { require3dsHeaders: action.meta.require3dsHeaders },
  };

  if (token) {
    proxiedBody.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(internalUrl, {
    method: 'POST',
    headers: new Headers(sharedHeaders),
    body: JSON.stringify({
      requestedUrl: url,
      appName: 'Product Movement',
      requestTime: new Date(),
      sessionId: auth?.sessionId,
      ...proxiedBody,
    }),
  }).then(async res => {
    next(action);

    if (!res.ok) {
      return handleNonOkResponse(res, url, logoutProcedure);
    }

    const responseBody = await handleOkResponse(res);
    return responseBody;
  });
};
