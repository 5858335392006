import { useState } from 'react';
import { PAYMENT_STAGES } from '..';

const usePaymentStage = () => {
  const [paymentStage, goToPaymentStage] = useState(PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD);

  return { paymentStage, goToPaymentStage };
};

export default usePaymentStage;
