import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import startPurchase from '../helpers/startPurchase';
import { PAYMENT_STAGES } from '../PaymentContextProvider';

const handleSuccessfulPurchase = (paymentContext, authorizationOutputToken) => {
  const { clearPaymentErrors, addPaymentToken, goToPaymentStage } = paymentContext;

  clearPaymentErrors();
  addPaymentToken({ authorizationOutput: authorizationOutputToken });
  goToPaymentStage(PAYMENT_STAGES.REQUIRES_FULFILLMENT);
};

const handleUnsuccessfulPurchase = paymentContext => {
  const { raisePaymentError, restartPaymentFlow } = paymentContext;

  raisePaymentError({
    message: 'UNSUCCESSFUL_PURCHASE',
    group: PAYMENT_ERROR_GROUPS.AUTHORIZATION,
    type: PAYMENT_ERROR_TYPES.AUTHORIZATION.UNSUCCESSFUL_PURCHASE_RETRY,
  });
  restartPaymentFlow();
};

/*
  Responsible for moving from the 3DS stage to either:
  - Fulfillment, on success
  - Payment Method Capture, on failure (to allow a retry)
*/
const paymentAuthorizedTransition = (paymentContext, authorizationOutputToken) => async dispatch => {
  const { tokens, upgradeOffer } = paymentContext;

  const { complete } = await dispatch(
    startPurchase({
      targetOfferId: upgradeOffer.id,
      paymentMethodToken: tokens.paymentMethod,
      authorizationToken: authorizationOutputToken,
    })
  );

  if (complete) {
    // Purchase successful, we should now be able to make a change to the customers subscription
    handleSuccessfulPurchase(paymentContext, authorizationOutputToken);
  } else {
    // Purchase unsuccessful, return to payment method capture to retry the process
    handleUnsuccessfulPurchase(paymentContext);
  }
};

export default paymentAuthorizedTransition;
