import React, { useEffect, useState } from 'react';
import defaultTheme from '@nebula/theme';
import Spinner from '@nebula/spinner';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import PaymentInformation from './nebulaPaymentInformation/paymentInformation';
import UpdateCardDetails from './nebulaPaymentInformation/updateCardDetails/updateCardDetails';
import { familyName, splitsList, applePay } from '../../constants';
import { isObjectEmpty } from '../../helpers';
import getSubscriptionData from '../../helpers/subscription/getSubscriptionData';
import { getFormattedExpiryDate } from '../../helpers/billing';
import { cardDetailsSchema, subscriptionSchema } from '../../schemas';
import { publishOnClickCancelECD } from './nebulaPaymentInformation/nebulaPaymentInformationsEcds';
import {
  StyledButton,
  StyledButtonsContainer,
  StyledChangePaymentMethodSection,
} from './nebulaAccountPaymentSection.styles';
import uiChangeState from '../../actions/uiChangeState';
import { getSplitStatus } from '../../reducers/split';
import getCurrentSubscriptionPrice from '../../reducers/selectors/currentSubscriptionPrice';
import { selectPage } from '../../reducers/uiState';
import ChangePaymentMethod from './nebulaPaymentInformation/updateCardDetails/changePaymentMethod';
import {
  addPaymentMethod,
  getPaymentMethods,
  resetCardSaved,
  addApplePayPaymentMethod,
} from '../../actions/billing/paymentMethods';
import { ApplePayDetailsCantBeUpdatedAlert } from './nebulaPaymentInformation/currentPaymentMethod/paymentMethodAlerts';
import CardSaveError from '../../exceptions/cardSaveError';
import { canMakeApplePayPayments } from './nebulaPaymentInformation/applePay/applePayHelpers';

const newCardFormName = 'productMovement/newCard';
const NebulaAccountPaymentInformationSection = ({
  nextBillDate,
  noFeeSubscription,
  pendingCancellation,
  currentSubscriptionPrice,
  nextBillingPrice,
  currentSubscription,
  card,
  mobilePay,
  directDebit,
  isRecycle,
  splits,
  uiChangeStateAction,
  getPaymentMethodsAction,
  addPaymentMethodAction,
  resetCardSavedAction,
  addApplePayPaymentMethodAction,
  submitAction,
  loading,
  showLoadingSpinner,
  showChangePaymentMethod,
  newCardForm,
  billing,
}) => {
  const validCard = !!card && !isObjectEmpty(card);
  let expirationDate = null;
  if (validCard) {
    const { expirationMonth, expirationYear } = card;
    expirationDate = getFormattedExpiryDate(`${expirationMonth}/${expirationYear}`);
  }

  const { applePayOption, cardOption } = applePay;
  const [initialPaymentMethod, setInitialPaymentMethod] = useState();
  const showApplePay = canMakeApplePayPayments();
  const [paymentMethodOption, setPaymentMethodOption] = useState(showApplePay ? initialPaymentMethod : cardOption);

  const canSaveCardForm = !newCardForm?.syncErrors;

  const isRecycleSplit = isRecycle && splits[splitsList.showRecycleBanner];
  const subsData = getSubscriptionData(
    nextBillDate,
    noFeeSubscription,
    pendingCancellation,
    currentSubscriptionPrice,
    nextBillingPrice,
    familyName[currentSubscription?.family],
    true
  );
  const onCancelClick = e => {
    e.preventDefault();
    uiChangeStateAction(window.location.pathname, {
      showCardForm: false,
      showChangePaymentMethod: false,
      shouldClearErrorsOfType: CardSaveError.errorType,
      showLoadingSpinner: false,
    });
    publishOnClickCancelECD();
  };

  const onSaveClick = async e => {
    e.preventDefault();
    uiChangeStateAction(window.location.pathname, {
      showCardForm: true,
      showLoadingSpinner: true,
    });
    await submitAction(newCardFormName);
    if (newCardForm.submitFailed) {
      return;
    }
    const modifiedCardForm = { ...newCardForm };
    modifiedCardForm.values.creditCardNumber = modifiedCardForm.values.creditCardNumber.replace(/\s/g, '');
    await addPaymentMethodAction(modifiedCardForm.values, currentSubscription.offerId, initialPaymentMethod);
  };

  useEffect(() => {
    if (billing?.cardSaved === true) {
      uiChangeStateAction(window.location.pathname, {
        showChangePaymentMethod: false,
        showChangePaymentSuccess: true,
        showLoadingSpinner: false,
      });
      getPaymentMethodsAction();
      setPaymentMethodOption(showApplePay ? cardOption : applePayOption);
      resetCardSavedAction();
    }
    if (billing?.applePaySaved) {
      uiChangeStateAction(window.location.pathname, {
        showChangePaymentMethod: false,
        showChangePaymentSuccess: true,
        showLoadingSpinner: false,
      });
      getPaymentMethodsAction();
      setPaymentMethodOption(applePayOption);
      resetCardSavedAction();
    }
    if (billing?.applePayFailed) {
      uiChangeStateAction(window.location.pathname, {
        showChangePaymentMethod: false,
        showChangePaymentSuccess: false,
        showApplePayChangePaymentFail: true,
        showLoadingSpinner: false,
      });
      getPaymentMethodsAction();
      setPaymentMethodOption(cardOption);
      resetCardSavedAction();
    }
  }, [billing?.cardSaved, billing?.applePaySaved, billing?.applePayFailed]);

  useEffect(() => {
    const applePayButton = document.getElementById('ApplePayButton');
    if (applePayButton) {
      const handleClick = () => {
        addApplePayPaymentMethodAction(
          currentSubscription,
          currentSubscriptionPrice,
          nextBillDate,
          initialPaymentMethod
        );
      };
      applePayButton.addEventListener('click', handleClick);

      return () => {
        applePayButton.removeEventListener('click', handleClick);
      };
    }
    return undefined;
  }, [showApplePay, paymentMethodOption, showChangePaymentMethod]);

  useEffect(() => {
    if (billing?.mobile) {
      setInitialPaymentMethod(applePayOption);
      setPaymentMethodOption(showApplePay ? applePayOption : cardOption);
    } else if (billing?.card) {
      setInitialPaymentMethod(cardOption);
      setPaymentMethodOption(cardOption);
    }
    if (billing?.loading) {
      uiChangeStateAction(window.location.pathname, {
        showLoadingSpinner: true,
      });
    }
    if (!billing?.loading) {
      uiChangeStateAction(window.location.pathname, {
        showLoadingSpinner: false,
      });
    }
  }, [billing.loading]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <PaymentInformation
        validCard={validCard}
        mobilePay={mobilePay}
        directDebit={directDebit}
        card={card}
        expirationDate={expirationDate}
        isRecycleSplit={isRecycleSplit}
        subscriptionsData={subsData}
        initialPaymentMethod={initialPaymentMethod}
        showApplePay={showApplePay}
        loading={billing.loading}
      />
      {showChangePaymentMethod && (
        <StyledChangePaymentMethodSection>
          {!showLoadingSpinner && (
            <React.Fragment>
              {showApplePay && (
                <ChangePaymentMethod
                  paymentMethodOption={paymentMethodOption}
                  setPaymentMethodOption={setPaymentMethodOption}
                  data-automation-test-element="apple-pay-radio-buttons"
                />
              )}
              {initialPaymentMethod === applePayOption && !showApplePay && <ApplePayDetailsCantBeUpdatedAlert />}
              {(paymentMethodOption === applePayOption || !showApplePay) && <UpdateCardDetails />}
              <StyledButtonsContainer>
                {showApplePay && paymentMethodOption === cardOption && (
                  <apple-pay-button
                    buttonstyle="black"
                    type="continue"
                    locale="en-GB"
                    id="ApplePayButton"
                    data-automation-test-element="applePayButton"
                  />
                )}
                {(paymentMethodOption !== cardOption || !showApplePay) && (
                  <StyledButton
                    label="Save"
                    onClick={onSaveClick}
                    disabled={loading || !canSaveCardForm}
                    data-automation-test-element="saveCard"
                  />
                )}
                <StyledButton
                  label="Cancel"
                  buttonKind="secondaryBlue"
                  onClick={onCancelClick}
                  data-automation-test-element="cancelButton"
                />
              </StyledButtonsContainer>
            </React.Fragment>
          )}
          {showLoadingSpinner && (
            <div data-automation-test-element="loadingSpinner">
              <Spinner loadingMessage="Loading..." spinnerSize="3rem" />
            </div>
          )}
        </StyledChangePaymentMethodSection>
      )}
    </ThemeProvider>
  );
};

NebulaAccountPaymentInformationSection.propTypes = {
  card: PropTypes.shape(cardDetailsSchema),
  mobilePay: PropTypes.shape({
    paymentType: PropTypes.string,
  }),
  directDebit: PropTypes.shape({
    accountNumber: PropTypes.string,
  }),
  nextBillDate: PropTypes.string,
  currentSubscription: PropTypes.shape(subscriptionSchema),
  noFeeSubscription: PropTypes.bool,
  pendingCancellation: PropTypes.bool,
  currentSubscriptionPrice: PropTypes.number,
  nextBillingPrice: PropTypes.number,
  splits: PropTypes.shape({
    showRecycleBanner: PropTypes.bool,
  }).isRequired,
  isRecycle: PropTypes.bool,
  uiChangeStateAction: PropTypes.func,
  getPaymentMethodsAction: PropTypes.func,
  addPaymentMethodAction: PropTypes.func,
  addApplePayPaymentMethodAction: PropTypes.func,
  resetCardSavedAction: PropTypes.func,
  submitAction: PropTypes.func,
  loading: PropTypes.bool,
  showChangePaymentMethod: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
  newCardForm: PropTypes.shape(),
  billing: PropTypes.shape({
    loading: PropTypes.bool,
    authorized: PropTypes.bool,
    card: PropTypes.shape({}),
    mobile: PropTypes.shape({}),
    cardSaved: PropTypes.bool,
    applePaySaved: PropTypes.bool,
    applePayFailed: PropTypes.bool,
    challengeUrl: PropTypes.string,
    fetchFailed: PropTypes.bool,
    endDate: PropTypes.string,
    freeTrialTerms: PropTypes.shape({
      remaining: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
    nextBillingDate: PropTypes.string,
  }).isRequired,
};
NebulaAccountPaymentInformationSection.defaultProps = {
  card: null,
  mobilePay: null,
  directDebit: null,
  nextBillDate: '',
  currentSubscription: '',
  noFeeSubscription: false,
  pendingCancellation: false,
  currentSubscriptionPrice: null,
  nextBillingPrice: null,
  isRecycle: false,
  uiChangeStateAction: undefined,
  getPaymentMethodsAction: undefined,
  addPaymentMethodAction: undefined,
  addApplePayPaymentMethodAction: undefined,
  resetCardSavedAction: undefined,
  submitAction: undefined,
  loading: false,
  showChangePaymentMethod: false,
  showLoadingSpinner: false,
  newCardForm: undefined,
};
const mapReduxStateToProps = state => ({
  showChangePaymentMethod: selectPage(state?.uiState, window?.location?.pathname)?.showChangePaymentMethod,
  nextBillDate: state?.billing?.nextBillingDate,
  card: state?.billing?.card,
  mobilePay: state?.billing?.mobile,
  directDebit: state?.billing?.directDebit,
  currentSubscription: state?.subscriptions?.current,
  noFeeSubscription: state?.billing?.signupPrice === 0,
  pendingCancellation: !!state?.subscriptions?.current?.defermentInfo,
  currentSubscriptionPrice: getCurrentSubscriptionPrice(state),
  nextBillingPrice: getCurrentSubscriptionPrice(state),
  splits: getSplitStatus(state?.split, [splitsList?.showRecycleBanner, splitsList?.applePay]),
  isRecycle: state?.billing?.billingStateRecycle,
  newCardForm: state?.form ? state.form[newCardFormName] : undefined,
  billing: state?.billing,
  showLoadingSpinner: selectPage(state?.uiState, window?.location?.pathname)?.showLoadingSpinner,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uiChangeStateAction: uiChangeState,
      addPaymentMethodAction: addPaymentMethod,
      getPaymentMethodsAction: getPaymentMethods,
      submitAction: submit,
      resetCardSavedAction: resetCardSaved,
      addApplePayPaymentMethodAction: addApplePayPaymentMethod,
    },
    dispatch
  );

export default connect(mapReduxStateToProps, mapDispatchToProps)(NebulaAccountPaymentInformationSection);
