import { getEnv } from '../../../../components/Context/env';
import {
  publishOnErrorECD,
  publishPaymentMethodUpdatedECD,
  paymentUpdateFailed,
} from '../nebulaPaymentInformationsEcds';
import { APPLEPAY_PAYMENT_METHOD_SAVED } from '../../../../actions/billing/paymentMethods';

const env = getEnv();

const getSubscriptionCost = (currentSubscriptionPrice, currentEnv) =>
  currentEnv === 'dev' || currentEnv === 'int' ? '0.01' : `${currentSubscriptionPrice}`;

const generateApplePayLineItem = (currentSubscription, currentSubscriptionPrice, nextBillDate) => ({
  label: `Monthly ${currentSubscription?.family} Subscription`,
  amount: getSubscriptionCost(currentSubscriptionPrice, env.REACT_APP_ENV),
  paymentTiming: 'recurring',
  recurringPaymentStartDate: `${nextBillDate}`,
});

const recurringPaymentRequest = (applePayLineItem, publicHost) => ({
  paymentDescription: 'Switching your Experian subscription payment method to ApplePay.',
  regularBilling: applePayLineItem,
  managementURL: publicHost,
  tokenNotificationURL: publicHost,
});

const applePayPaymentRequest = (currentSubscriptionPrice, applePayLineItem) => ({
  countryCode: 'GB',
  currencyCode: 'GBP',
  merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
  supportedNetworks: ['visa', 'masterCard'],
  lineItems: [applePayLineItem],
  recurringPaymentRequest: recurringPaymentRequest(applePayLineItem, env.REACT_APP_PUBLIC_HOST),
  total: {
    label: 'Experian',
    amount: getSubscriptionCost(currentSubscriptionPrice, env.REACT_APP_ENV),
  },
});

const validateMerchant = async (session, dispatch, ECDUpdateMethodText) => {
  try {
    const response = await fetch(`${env.REACT_APP_PUBLIC_HOST}/applepay-merchant-session`, { method: 'POST' });
    const merchantSession = await response.json();
    session.completeMerchantValidation(merchantSession);
    return null;
  } catch (error) {
    session.abort();
    publishOnErrorECD(error);
    publishPaymentMethodUpdatedECD({
      updateStatus: paymentUpdateFailed,
      attemptedUpdateMethod: ECDUpdateMethodText,
    });
    return dispatch({
      payload: {
        applePayFailed: true,
      },
      type: APPLEPAY_PAYMENT_METHOD_SAVED,
    });
  }
};

const canMakeApplePayPayments = () => {
  const canMakePayment = window?.ApplePaySession?.canMakePayments?.();
  return !!canMakePayment;
};

export {
  applePayPaymentRequest,
  generateApplePayLineItem,
  validateMerchant,
  recurringPaymentRequest,
  getSubscriptionCost,
  canMakeApplePayPayments,
};
