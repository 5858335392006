import styled, { css } from 'styled-components';
import { TitleXSmall500, BodySmall500 } from '@nebula/global-styles';

const getBackgroundColor = (state, theme) => {
  switch (state) {
    case 'error':
      return theme.color.messaging.red[10];
    case 'disabled':
      return theme.color.ui.grey[10];
    default:
      return theme.color.brand.white;
  }
};

const getBorderColor = (state, theme) => {
  switch (state) {
    case 'error':
      return theme.color.messaging.red[60];
    case 'disabled':
      return theme.color.ui.grey[20];
    default:
      return theme.color.ui.grey[70];
  }
};

const recurlyElementStyles = css`
  .recurly-element {
    width: 100%;
    height: 2.375rem;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background-color: ${({ theme, $state }) => getBackgroundColor($state, theme)};
    color: ${({ theme }) => theme.color.ui.grey[70]};
    font-weight: 400;
    font-size: 1rem;
  }
`;

const baseInputStyles = css`
  display: grid;
  align-items: center;
  width: 100%;
  height: 3rem;
  border-style: solid;
  padding: 0.25rem 0.75rem;
  cursor: ${({ $state }) => ($state === 'disabled' ? 'not-allowed' : 'unset')};
  background-color: ${({ theme, $state }) => getBackgroundColor($state, theme)};
  border-color: ${({ theme, $state }) => getBorderColor($state, theme)};
  border-width: ${({ $state }) => ($state === 'error' ? '2px' : '1px')};
  border-radius: ${({ theme }) => theme.borderRadius.xsSmall.rem};
  box-shadow: none;
  transition: ${({ theme }) => theme.focus.transition};
  box-shadow: ${({ theme, $focused }) => $focused && `${theme.focus.shadow}`};

  &:hover {
    ${({ theme, $state }) => $state !== 'error' && $state !== 'disabled' && `border-color: ${theme.color.ui.grey[90]}`};
  }
`;

export const Form = styled.form`
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    max-width: 19.4375rem;
  }
`;

export const Label = styled(TitleXSmall500)`
  margin: 1rem 0 0.5rem;
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
`;

export const CardSummary = styled.div`
  display: flex;
  margin: 1rem 0 0.5rem;

  ${Label} {
    flex: 1;
    margin: 0;
  }
`;

export const CardNumberInput = styled.div`
  ${baseInputStyles}
  ${recurlyElementStyles}
  grid-auto-flow: column;
  grid-template-columns: auto 1fr 2.25rem;
  grid-gap: 0.5rem;

  > svg {
    justify-self: center;
  }
`;

export const NameInput = styled.div`
  ${baseInputStyles}
  ${recurlyElementStyles}
  grid-auto-flow: column;
  grid-gap: 0.5rem;
`;

export const ExpiryDate = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
`;

export const DateInput = styled.div`
  ${baseInputStyles}
  ${recurlyElementStyles}
  position: relative;

  > svg {
    position: absolute;
    right: 0.75rem;
  }
`;

export const SecurityCode = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-columns: 120px minmax(auto, 175px);
  align-items: center;
  margin: 0 0 0.75rem;
`;

export const SecurityInput = styled.div`
  ${baseInputStyles}
  ${recurlyElementStyles}
  grid-auto-flow: column;
  grid-gap: 1rem;
`;

export const SecurityIcon = styled(BodySmall500)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.75rem;
  margin: 0;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.color.decorative.sapphire[10]};
  border-radius: 0.5rem;
`;
