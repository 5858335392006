import { useCallback, useState } from 'react';
import { DEFAULT_TOKENS_STATE } from '..';

const usePaymentTokens = () => {
  const [tokens, setTokens] = useState(DEFAULT_TOKENS_STATE);
  const addPaymentToken = useCallback(token => setTokens(previous => ({ ...previous, ...token })), [setTokens]);

  return { tokens, addPaymentToken, setTokens };
};

export default usePaymentTokens;
