import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RecurlyProvider } from '@recurly/react-recurly';

import PaymentCaptureMethod from '../PaymentMethodCapture';
import PaymentContextProvider, { PAYMENT_STAGES, PaymentContext } from './PaymentContextProvider';
import PaymentMethodAuthorization from '../PaymentMethodAuthorization';
import PaymentProcessing from '../PaymentProcessing';
import { getEnv } from '../Context/env';

export const PaymentStages = ({ heading }) => {
  const paymentContext = useContext(PaymentContext);
  const { paymentStage, restartPaymentFlow } = paymentContext;

  switch (paymentStage) {
    case PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD:
      return <PaymentCaptureMethod heading={heading} />;
    case PAYMENT_STAGES.REQUIRES_AUTHORIZATION:
      return <PaymentMethodAuthorization />;
    case PAYMENT_STAGES.REQUIRES_FULFILLMENT:
      return <PaymentProcessing />;
    default:
      restartPaymentFlow();
      return null;
  }
};

PaymentStages.propTypes = {
  heading: PropTypes.string,
};

PaymentStages.defaultProps = {
  heading: 'Placeholder',
};

const PaymentOrchestrator = ({ upgradeOffer, heading }) => {
  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();

  return (
    <PaymentContextProvider upgradeOffer={upgradeOffer}>
      <RecurlyProvider publicKey={key}>
        <PaymentStages heading={heading} />
      </RecurlyProvider>
    </PaymentContextProvider>
  );
};

PaymentOrchestrator.propTypes = {
  heading: PropTypes.string,
  upgradeOffer: PropTypes.shape({
    family: PropTypes.string,
    name: PropTypes.string,
    upc: PropTypes.string,
    id: PropTypes.string,
    descriptors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

PaymentOrchestrator.defaultProps = {
  heading: 'Placeholder',
};

export default PaymentOrchestrator;
