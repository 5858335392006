import { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Element, InputState, MaxNameLength, MinNameLength } from '../constants';

/**
 * Custom hook to handle input state changes.
 *
 * @param {Object} initialInputStates - The initial states of the inputs.
 * @returns {Object} - An object containing the current input states, a function to handle input changes, and the input style for Recurly.
 *
 * @property {Object} newInputStates - The current states of the inputs.
 * @property {Function} handleInputChange - Function to handle changes in input states.
 * @property {Object} recurlyInputStyle - The style object for Recurly input fields.
 *
 * @example
 * const { newInputStates, handleInputChange, recurlyInputStyle } = useInputHandler(initialInputStates);
 *
 * handleInputChange(Element.FirstName, { valid: true, empty: false, value: 'Andy' });
 */

const useInputHandler = initialInputStates => {
  const [newInputStates, setNewInputStates] = useState(initialInputStates);

  const handleInputChange = (element, { valid, empty, value }) => {
    let newState;
    if (element === Element.FirstName || element === Element.LastName) {
      newState = value.length >= MinNameLength && value.length < MaxNameLength ? InputState.Success : InputState.Error;
    } else if (empty) {
      newState = InputState.Standard;
    } else if (valid) {
      newState = InputState.Success;
    } else {
      newState = InputState.Error;
    }
    setNewInputStates(prevState => ({ ...prevState, [element]: newState }));
  };

  const theme = useContext(ThemeContext);

  const getRecurlyInputStyle = content => ({
    fontColor: theme.color.ui.grey[70],
    fontSize: '1rem',
    fontWeight: 400,
    placeholder: {
      content,
      color: theme.color.ui.grey[70],
    },
  });

  return {
    newInputStates,
    handleInputChange,
    getRecurlyInputStyle,
  };
};

export default useInputHandler;
