import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardCvvElement } from '@recurly/react-recurly';
import { useMediaQuery } from '@nebula/hooks';

import { Element, InputState } from '../constants';
import useFocusHandler from '../hooks/useFocusHandler';
import useFormValidation from '../hooks/useFormValidation';
import useInputHandler from '../hooks/useInputHandler';
import { ValidationStatusIcon, Cvv } from './Icons';
import { SecurityCode, SecurityInput, SecurityIcon } from './index.styles';

const SecurityCodeComponent = ({ onSecurityCodeStateChange, onFormValidityChange }) => {
  const [inputStates, setInputStates] = useState({ [Element.Cvv]: InputState.Standard });

  const isSmallMobile = useMediaQuery('(min-width: 365px)');

  const { newInputStates, handleInputChange, getRecurlyInputStyle } = useInputHandler(inputStates, setInputStates);
  const { focusedElement, handleFocus, handleBlur } = useFocusHandler();

  useFormValidation([newInputStates], onFormValidityChange);

  useEffect(() => {
    onSecurityCodeStateChange(newInputStates[Element.Cvv]);
  }, [newInputStates, onSecurityCodeStateChange]);

  return (
    <SecurityCode>
      <SecurityInput $state={newInputStates.cvv} $focused={focusedElement === Element.Cvv}>
        <CardCvvElement
          onChange={event => {
            handleInputChange('cvv', event);
          }}
          onFocus={handleFocus(Element.Cvv)}
          onBlur={handleBlur}
          style={getRecurlyInputStyle('CVC/CVV')}
        />
        <ValidationStatusIcon state={newInputStates.cvv} />
      </SecurityInput>
      <SecurityIcon>
        {isSmallMobile && <Cvv />}
        Last 3 digits on the back of your card
      </SecurityIcon>
    </SecurityCode>
  );
};

SecurityCodeComponent.defaultProps = {
  onSecurityCodeStateChange: () => {},
};

SecurityCodeComponent.propTypes = {
  onSecurityCodeStateChange: PropTypes.func,
  onFormValidityChange: PropTypes.func.isRequired,
};

export default SecurityCodeComponent;
