export const PAYMENT_ERROR_GROUPS = {
  TOKENIZATION: 'TOKENIZATION_ERROR',
  AUTHORIZATION: 'AUTHORIZATION_ERROR',
  FULFILLMENT: 'FULFILLMENT_ERROR',
};

export const PAYMENT_ERROR_TYPES = {
  TOKENIZATION: {
    GENERATING_PAYMENT_TOKEN: 'GENERATING_PAYMENT_TOKEN',
    INITIATING_PURCHASE: 'INITIATING_PURCHASE',
  },
  AUTHORIZATION: {
    UNSUCCESSFUL_3DS: 'UNSUCCESSFUL_3DS',
    UNSUCCESSFUL_PURCHASE_RETRY: 'UNSUCCESSFUL_PURCHASE_RETRY',
  },
  FULFILLMENT: {
    INITIATING_FULFILLMENT: 'INITIATING_FULFILLMENT',
  },
};
