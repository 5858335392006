import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import { sendMobileErrorNotification, sendMobileLoadedNotification } from '@experian-uk/web-common-mobile-view-helpers';

import isValidSwitch from '../../../helpers/switch/isValidSwitch';
import usePageView from '../../../hooks/usePageView';
import useTargetOffer from '../../../hooks/useTargetOffer';
import useCurrentOffer from '../../../hooks/useCurrentOffer';
import { products } from '../../../constants';
import PaymentOrchestrator from '../../../components/PaymentOrchestrator';
import TemporaryOfferDetails from '../../../components/PaymentMethodCapture/TemporaryOfferDetails';

const PAGE_NAME = 'ProdMoveSwitchPageMobile';

const WEBVIEW_SUPPORTED_UPGRADE_FAMILIES = [products.identity, products.credit_expert];

const MobileSwitchPage = () => {
  usePageView(PAGE_NAME);

  const currentOffer = useCurrentOffer();
  const switchTarget = useTargetOffer();
  const validSwitch = isValidSwitch(currentOffer, switchTarget);
  const webviewSupported = WEBVIEW_SUPPORTED_UPGRADE_FAMILIES.includes(switchTarget?.family);

  useEffect(() => {
    if (switchTarget && validSwitch && webviewSupported) {
      sendMobileLoadedNotification();
    } else {
      sendMobileErrorNotification();
    }
  }, [switchTarget, validSwitch, webviewSupported]);

  if (!switchTarget || !validSwitch || !webviewSupported) {
    return null;
  }

  return (
    <ThemeProvider theme={nebulaTheme}>
      <PaymentOrchestrator upgradeOffer={switchTarget} heading="Switch Migration - Webview" />
      <TemporaryOfferDetails offer={switchTarget} />
    </ThemeProvider>
  );
};

export default MobileSwitchPage;
