import { TitleLarge, TitleXLarge, TitleMedium400, TitleXSmall500, BodyLarge400 } from '@nebula/global-styles';
import styled from 'styled-components';

export const Heading = styled.h2`
  ${TitleLarge}
  margin: 0 0 48px 0;
  font-weight: ${({ theme }) => theme.font.weightMedium};

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    ${TitleXLarge}
  }
`;

export const Container = styled.section`
  display: flex;
  gap: 48px;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 24px;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    flex-direction: row;
  }

  > div {
    align-items: flex-start;
    flex-basis: 0;
    flex-grow: 1;
  }

  ${TitleXSmall500} {
    margin: 0;
  }
  ${BodyLarge400} {
    margin: 0;
  }
`;

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailsContainer = styled.div`
  p {
    margin: 4px 0;
  }
`;

export const DetailValue = styled(TitleMedium400)`
  text-align: right;
`;

export const StyledSpacerDiv = styled.div`
  height: 48px;
`;
