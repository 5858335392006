import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';

import { ContentContainer, IFrame } from '../../../pages/switch/index.styles';
import { DONE_MESSAGE } from '../../../pages/challenge-finished';
import getEnv from '../../Context/env/getEnv';
import withEnv from '../../Context/env/decorator';
import addEvent from '../../../actions/ecd/addEvent';
import flagChallengeFinished from '../../../actions/billing/flagChallengeFinished';
import initializeEcd from '../../../actions/ecd/initialize';
import PanelHeader from '../../PanelHeader';

const submitMilestoneECD = milestone => {
  Analytics.publishOnce(MilestoneInputEvent.fromObject(milestone));
};

@withEnv
@connect(
  state => ({
    billing: state.billing,
    initializeEcd: PropTypes.func,
  }),
  dispatch =>
    bindActionCreators(
      {
        addEvent,
        flagChallengeFinished,
        initializeEcd,
      },
      dispatch
    )
)
export default class Challenge extends React.Component {
  static propTypes = {
    addEvent: PropTypes.func.isRequired,
    challengeUrl: PropTypes.string.isRequired,
    initializeEcd: PropTypes.func,
    flagChallengeFinished: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initializeEcd: () => ({}),
  };

  constructor(props) {
    super(props);

    this.iframeRef = React.createRef();
    this.handleMessageFromIframe = this.handleMessageFromIframe.bind(this);
  }

  state = {
    postMessageReceived: false,
  };

  componentDidMount() {
    window.addEventListener('message', this.handleMessageFromIframe);
    submitMilestoneECD({
      product_movement_challenge_page_load: {
        interaction_status: true,
      },
    });

    submitMilestoneECD({
      product_movement_switch: {
        journey_type: '3DS Challenge',
        page_funnel_step: 'Started - Step 3',
      },
    });
    this.props.initializeEcd();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageFromIframe);
  }

  handleMessageFromIframe(event) {
    if (this.state.postMessageReceived) {
      return;
    }
    const host = getEnv().REACT_APP_PUBLIC_HOST;
    submitMilestoneECD({
      product_movement_iframe_message_1: {
        interaction_status: true,
      },
    });
    if (!event || event.origin !== host) {
      return;
    }

    submitMilestoneECD({
      product_movement_iframe_message_2: {},
    });

    if (event.source !== this.iframeRef.current.contentWindow) {
      return;
    }

    submitMilestoneECD({
      product_movement_iframe_message_3: {},
    });

    if (event.data !== DONE_MESSAGE) {
      return;
    }

    submitMilestoneECD({
      product_movement_iframe_message_4: {},
    });

    submitMilestoneECD({
      product_movement_switch: {
        journey_type: '3DS Challenge',
        page_funnel_step: 'Finished - Step 3',
      },
    });
    this.props.initializeEcd();

    this.setState({ postMessageReceived: true });
    this.props.flagChallengeFinished();

    submitMilestoneECD({
      product_movement_challenge_done: {
        interaction_status: true,
      },
    });
  }

  render() {
    return (
      <ContentContainer>
        <PanelHeader
          headingStart="Payment authentication"
          subtitle="Please follow the instructions from your card provider below and do not refresh the page or use the browser back button."
          setAutomationTitle="challengeTitle"
          setAutomationSubtitle="challengeSubtitle"
        />
        <IFrame ref={this.iframeRef} {...setAutomationElement('3DS_Window')} src={this.props.challengeUrl} />
      </ContentContainer>
    );
  }
}
