import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import Analytics from '@experian-uk/web-common-analytics';
import { FooterOOS } from '@experian-uk/corvetteuk-common-ui';
import isServer from '../../helpers/isServer';
import {
  ContentContainer,
  BodyContent,
  ContentHeader,
  OrderedList,
  InlineLink,
  InnerBodyContent,
  ErrorMessage,
} from './index.styles';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import NavigationWrapper from '../../components/NavigationWrapper';
import { errorCodes } from '../../constants';

const contactUsMessage = (
  <Fragment>
    Contact us on 03444 818182<sup>*</sup> for further assistance.
  </Fragment>
);

const contactUsDetailedMessage = (
  <Fragment>
    <sup>*</sup> Calls to 0344 numbers are charged at the UK landline rate and are usually inclusive of monthly phone
    contract packages. Calls may be recorded for training and monitoring purposes.
  </Fragment>
);

const retryBreachAcquisition = {
  heading: 'Oops, something went wrong',
  messages: ['Please click here to try again.'],
  buttonText: 'Retry',
  critical: true,
};

export const errorMessageMap = {
  [errorCodes.attributionError]: {
    heading: (
      <React.Fragment>
        Hmm, we can&rsquo;t seem to confirm that code. <br />
        {contactUsMessage}
      </React.Fragment>
    ),
    messages: contactUsDetailedMessage,
    critical: true,
  },
  [errorCodes.serviceUnavailable]: retryBreachAcquisition,
};

const getErrorDetails = (errors, errorCode, errorMessageProps) => {
  if (errorMessageMap[errorCode]) {
    return {
      ...errorMessageMap[errorCode],
      ...errorMessageProps,
    };
  }
  if (errors.length > 0) {
    return errors
      .filter(err => err.critical)
      .map(criticalError => {
        let errorAlertObject;
        try {
          errorAlertObject = JSON.parse(criticalError.error);
        } catch (error) {
          errorAlertObject = { messages: [criticalError.error] };
        }
        return errorAlertObject;
      })[0];
  }
  return {};
};

const getECD2PageName = path => {
  switch (path) {
    case 'activation':
      return 'Activation';

    case 'attributionError':
      return 'Attribution';

    case 'serviceUnavailable':
      return 'Service';

    case 'provider':
      return 'BillingProviderUnavailable';

    default:
      return 'Generic';
  }
};

@connect(
  state => ({
    retryPath: state.actionRetry.path,
    errors: state.error,
  }),
  dispatch =>
    bindActionCreators(
      {
        push,
      },
      dispatch
    )
)
export default class Error extends React.PureComponent {
  static propTypes = {
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        critical: PropTypes.bool,
        error: PropTypes.string,
      })
    ),
    match: PropTypes.shape({
      params: PropTypes.shape({
        errorCode: PropTypes.string,
      }),
    }).isRequired,
    push: PropTypes.func.isRequired,
    retryPath: PropTypes.string,
  };

  static defaultProps = {
    errors: [],
    retryPath: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { errorCode },
      },
    } = this.props;
    (async function initAnalytics() {
      if (!isServer()) {
        await Analytics.init({}, `ProdMoveErrorPage-${getECD2PageName(errorCode)}`);
      }
    })();
  }

  render() {
    const {
      errors,
      match: {
        params: { errorCode },
      },
      retryPath,
    } = this.props;
    let errorMessageProps;
    if (retryPath) {
      errorMessageProps = {
        buttonOnClick: () => this.props.push(retryPath),
      };
    }

    return (
      <Fragment>
        <NavigationWrapper isErrorPage />
        <ErrorMessage as="main" id="main">
          {errorCode === errorCodes.activation && (
            <ContentContainer>
              <ContentHeader
                headingStart="It looks like you already have an Experian account"
                subtitle="You’ll need to cancel your subscription before you can activate your new features"
                setAutomationTitle="errorActivationTitle"
                setAutomationSubtitle="errorActivationSubtitle"
              />
              <BodyContent>
                <InnerBodyContent>
                  <p>How to cancel:</p>
                  <OrderedList>
                    <li>
                      Go{' '}
                      <InlineLink
                        href="/switch?switchingTo=basic&offerId=313c52ce-5db2-4bf5-a289-2b587ad4cea3&reasonForCancel=breach"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </InlineLink>{' '}
                      to cancel your subscription
                    </li>
                    <li>
                      Your subscription will end on your billing date and you’ll receive an email confirming that the
                      subscription has been cancelled.
                    </li>
                    <li>
                      Once you’ve received your cancellation email, log in to your Experian account and go to ‘My
                      Subscriptions’.
                    </li>
                    <li>Select Identity Plus.</li>
                    <li>Enter your voucher code for your complimentary Identity Plus subscription to begin.</li>
                  </OrderedList>
                  <p>
                    Once you&apos;ve cancelled your subscription, you&apos;ll still have access to your Free Experian
                    Account and your Experian Credit Score.
                  </p>
                </InnerBodyContent>
              </BodyContent>
            </ContentContainer>
          )}
          {errorCode !== errorCodes.activation && (
            <ErrorAlert {...getErrorDetails(errors, errorCode, errorMessageProps)} />
          )}
        </ErrorMessage>
        <FooterOOS />
      </Fragment>
    );
  }
}
