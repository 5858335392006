import { useState, useCallback } from 'react';

const usePaymentErrors = () => {
  const [paymentErrors, setPaymentErrors] = useState([]);
  const raisePaymentError = useCallback(
    paymentError => setPaymentErrors(previousErrors => [...previousErrors, paymentError]),
    [setPaymentErrors]
  );
  const clearPaymentErrors = useCallback(() => setPaymentErrors([]), [setPaymentErrors]);

  return { paymentErrors, raisePaymentError, clearPaymentErrors };
};

export default usePaymentErrors;
